import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'

import Desc from '../desc/desc'

const Img = styled.img`
  /* width: 24px; */
  width: 50%;
  height: 50%;
  /* background-color: ${({ theme }) => theme.colorBlack}; */
  /* padding: 5px; */
  border-radius: 50%;
  /* border: 5px solid ${({ theme }) => theme.colorBlack}; */
  /* box-sizing: content-box; */
`
const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => (props.lowMargin ? '10px' : '25px')};
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.colorBlack};
  padding: 5px;
  border-radius: 50%;
  box-sizing: content-box;
`

const Wrap = styled.li`
  display: flex;
  align-items: center;
  margin: ${props => (props.lowMargin ? '5px 0' : '10px 0')};
  /* max-width: 200px; */
  @media (min-width: 960px) {
    width: 280px;
  }
`

const InfoContact = props => {
  return (
    <Wrap lowMargin={props.lowMargin || null}>
      <ImgWrap lowMargin={props.lowMargin || null}>
        <Img src={props.img} alt={'Ikonka danych kontaktowych'} />
      </ImgWrap>

      <Desc color={props.color || null} {...props}>
        {/* <ReactMarkdown
          disallowedTypes={['paragraph']}
          unwrapDisallowed
          children={props.children}
        /> */}
        <div
          dangerouslySetInnerHTML={{ __html: props.children }}
          className="cms-text"
        />
      </Desc>
    </Wrap>
  )
}

export default InfoContact
