import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://graphql.datocms.com/',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // Authorization: `Bearer ${process.env.GATSBY_DATO_CMS}`,

    Authorization: `Bearer 9c278e3e7044c00430ccddd1d8bdec`,
  },
})

export default instance
