import styled from 'styled-components'
import theme from '../../layout/theme'

const Loader = styled.article`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  transform: ${props =>
    props.isDataLoaded ? 'translateX(-100%)' : 'translateX(0%)'};
  z-index: 250;
  /* background-color: ${theme.colorYellow}; */
  background-color: ${theme.colorBlack};
  /* background-color: #fff; */
`

export default Loader
