import styled from 'styled-components'

const Header = styled.h2`
  font-size: ${props => (props.h2 ? '30px' : '22px')};
  text-transform: uppercase;
  text-align: ${props => props.align || 'center'};
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme.colorBlack};
  font-weight: 500;
  position: relative;
  /* &::first-letter {
    color: ${props => props.firstLetter && props.theme.colorYellow};
  } */
  margin-bottom: ${props => (props.h2 && !props.noMargin ? '25px' : null)};

  &::before {
    content: ${props => (props.yellowRect ? '""' : null)};
    position: absolute;
    left: -10px;
    top: 50%;
    z-index: 150;
    transform: translateY(-50%);
    height: 80%;
    width: 3px;
    background-color: ${({ theme }) => theme.colorYellow};
    /* background-color: ${({ theme }) => theme.colorOrange}; */
  }
  @media (min-width: 768px) {
    font-size: ${props => (props.h2 ? '34px' : '23px')};
  }
  @media (min-width: 960px) {
    font-size: ${props => (props.h2 ? '36px' : '24px')};
  }
  @media (min-width: 1200px) {
    font-size: ${props => (props.h2 ? '38px' : '25px')};
  }
  @media (min-width: 1400px) {
    font-size: ${props => (props.h2 ? '43px' : '26px')};
    margin-bottom: ${props => (props.h2 ? '30px' : null)};
  }
  @media (min-width: 1600px) {
    font-size: ${props => (props.h2 ? '48px' : '26px')};
    /* margin-bottom: ${props => (props.h2 ? '40px' : null)}; */
  }
`

export default Header
